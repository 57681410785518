// 3rd-party Plugins
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./assets/sass/style.react.scss";

// Default Layout themes
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
// }

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.zoom {
  transition: transform 0.2s;
}

.zoom:hover {
  transform: scale(1.5);
}

/* .hover {
  cursor: pointer;
}
 */

.hover-60:hover {
  opacity: 60%
}

.hover-72:hover {
  opacity: 2%!important;
}
.text-gray-3 {
  color: rgb(169, 168, 174)
}

.border-bottom-dark-gray {
  border-bottom: 2px solid #222224!important;
  margin-top: 40px;
  margin-bottom: 20px;
}

.specification-card {
  background-color: #131315!important;
  padding:25px
}

.specification-header {background-color: #222224 !important;
  border: 1px solid transparent !important;
  border-radius: 5px !important;}

.text-success-2 {
  color: #42dc82 !important;
}

.centered-container {
  position: absolute;
  top: 50%; /* Zentriert vertikal im sichtbaren Bereich */
  left: 50%; /* Zentriert horizontal */
  transform: translate(-50%, -50%); /* Verschiebung zur genauen Zentrierung */
}



.card-button {
  position: absolute;
  top: 200px; /* Adjust the top position as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: #007bff; /* Button color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
}

.card-blur {
  filter: blur(4px)
}

.peopleimg {
  border-radius: 8px;
}
.name-and-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.name-text {
  line-height: 1.7rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: #1a1a1a; /* extra-dark-gray */
  margin-bottom: 0;
}

.linkedin-icon {
  width: 20px;
  height: 20px;
}

.position-text {
  font-size: 1.1rem;
  font-weight: 500;
  color: #1a1a1a; /* extra-dark-gray */
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 1rem;
}

.position-relative {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: -10%;
  width: 120%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the background color and opacity as needed */
  z-index: 1;
}

.name-and-icon,
.position-text {
  position: relative;
  z-index: 2;
}