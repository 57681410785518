.aside {
  box-shadow: none !important;
}

.card-round {
  border-radius: 1rem !important;
}

// ### Custom

.custom-board {
  float: none;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-bottom: 1.25rem;
  margin-right: 1.25rem !important;
  background-color: #f3f6f9;
  border-radius: 0.42rem;
}

.custom-board .custom-board-header {
  border-top-left-radius: 0.42rem;
  border-top-right-radius: 0.42rem;
}

.custom-board header {
  font-size: 16px;
  padding: 15px;
}
.custom-drag {
  min-height: 200px;
  padding: 20px;
}

.custom-board .custom-drag .custom-item {
  border-radius: 0.42rem;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.09);
}

.custom-item {
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.custom-board .custom-board-header .custom-title-board {
  font-size: 1.2rem;
  font-weight: 500;
  color: #181c32;
}

.dot-green {
  background-color: rgb(129, 216, 0);
  width: 10px;
  height: 10px;
  border-radius: 200px;
  float: right;
}

.btn-xs {
  height: 20px;
  font-size: 0.8rem;
  border-radius: 0.42rem;
  padding: 0.15rem 0.75rem;
  border-radius: 0.42rem;
}

.button-link {
  color: #1eabe3;
  border: None;
  cursor: pointer;
  display: inline;
  background: transparent;
}

.button-link:hover,
.button-link:focus{
  color: steelblue;
}

.btn-success-green {
  color: #fff;
  background-color: #28A745;
  border-color: #28A745;
}

.btn-success-green.focus, .btn-success-green:focus {
  box-shadow: 0 0 0 0.2rem rgb(40, 167, 69 / 50%);
}

.btn-success-green:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1E7E34;
}

.btn-success-green:not(:disabled):not(.disabled).active, .btn-success-green:not(:disabled):not(.disabled):active, .show>.btn-success-green.dropdown-toggle {
  color: #fff;
  background-color: #1E7E34;
  border-color: #1C7430;
}

.btn-icon-green{
  color: #28A745 !important;
}

.btn-hover-custom-icon-danger:hover {
  i {
    color: #EE2D41 !important;
  }
}

.btn-hover-custom-icon-green:hover {
  i {
    color: #218838 !important;
  }
}

.btn-hover-custom-icon-success-green:hover {
  i {
    color: #28A745 !important;
  }
}

.custom-accordion{
  .card {
    .card-header:hover {
      background: #e0e0e0;
    }
    .accordion-selected {
      background: #3699FF;
      font-weight: bold;
    }
    .accordion-selected:hover {
      background: #318ce5;
    }
  }
}

.text-green{
  color: #28A745 !important;
}

.svg-icon-green{
  fill: #28A745 !important;
}

.__floater__open {
  transition: opacity 0.3s ease 0s, transform 0.0s ease 0s !important;
}

#tour, #languageToggle {
  border-radius: 60px;
}

#tour:hover, #languageToggle:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

#tour:hover > i {
  color: $primary
}

.text-hover-custom-primary:hover {
  color: #1479a1 !important;
}

.custom-row-hover:hover{
  background: $light !important;
}


.custom-row-hover:hover{
  background: $light;
}

.table-responsive {
  overflow-x: auto !important;
}

.link-widget:hover {
  background: aliceblue;
  .font-size-h4 {
    color: $primary !important
  }
}

.feedback-checked {
  color: gold;
}

@include media-breakpoint-up(sm) {
  .custom-rounded-right-md {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

@include media-breakpoint-down(sm){
  .custom-date-picker-indicator {
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
      margin: 0;
    }
  }
}

.tr-no-top-border {
  > td {
    border-top: None
  }
}

.sample-recipe:hover {
  background: aliceblue;
}

.country-icon {
  width: 22px;
  height: 18px;
  object-fit: cover;
  border-radius: 0.42rem;
}

.country-icon-lg {
  width: 24px !important;
  height: 20px !important;
}

.news-grid {
  display: flex;
  width: 100%;
}

.configurator-tab {
  background: white !important;
}

.configurator-tab:not(.active) {
  img {
    opacity: 0.3
  }
  .nav-text{
    opacity: 0.3
  }
}

.configurator-tab:not(.active):hover {
  img {
    opacity: 0.6;
  }
  .nav-text {
    opacity: 0.6;
    color: black !important;
  }
}

.show-more-footer {
  background-color: #fdfdff !important;
}

a.custom-link {
  color: black;
  font-weight: bolder;
}

a.custom-link:focus,
a.custom-link:active,
a.custom-link:hover {
  color: black;
  text-decoration: underline !important;
}


.information-listing-entry {
  height: 36px;
  width: 36px;
  object-fit: cover;
  overflow: hidden;
}

.information-listing-entry-element > td {
  border-top: 0 !important;
  padding-left: 0;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}

.tooltip-wide > .tooltip-inner {
  max-width: 100%;
}

.svg-icon-link {
  color: lightgray;
}

.svg-icon-link:hover {
  color: $primary;
}

.header-link {
  color: #828282 !important;
}

.header-link:hover {
  color: black !important;
}

.contact-link {
  color: #232323 !important;
}

.contact-link:hover {
  color: black !important;
}

//
//
// Overwrite styling from style.css that destroys configurator layout
//
//

.configurator-wrapper {
  .nav-icon span:nth-child(1){
    top: initial;
    width: initial;
  }
  .nav-icon span {
    display: initial;
    width: initial;
    height: initial;
    position: initial;
  }
  select {
    background: initial;
    -webkit-appearance: initial;
    appearance: auto;
  }
  h1, h2, h3, h4, h5 {
    font-size: initial;
    line-height: initial;
    margin-bottom: initial;
  }
  .mt-5{
    margin-top: 1.25rem !important;
  }
  .my-5{
    margin-top: 1.25rem !important;
  }
  input, textarea, select {
    margin: initial;
  }
  line-height: initial !important;
}
