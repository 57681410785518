.notification {
  display: inline-block;
  position: relative;
  border-radius: 45px;
  font-size: 1.3em;
  cursor: pointer;
}

.notification::before,
.notification::after {
  color: #fff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.notification::before {
  display: block;
  transform-origin: top center;
}

.notification::after {
  font-family: Arial;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 5px 8px;
  line-height: 100%;
  border: 1px #fff solid;
  border-radius: 60px;
  background: #db3434;
  opacity: 0;
  content: attr(data-count);
  transform: scale(0.5);
  transition: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.notification.show-count::after {
  transform: scale(1);
  opacity: 1;
}

.notification-info-panel {
  max-height: 420px;
  overflow-y: auto;
  padding: 0;
}

.notification-bell {
  transform: scale(-1, 1);
  color: rgba(0, 0, 0, 0.75) !important;
}

.notification:hover .notification-bell {
  color: $primary !important;
}

.notification:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.notification-clicked .notification-bell {
  color: $primary !important;
}

.notification-clicked {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@include media-breakpoint-up(xl) {
  #notifications {
    max-width: 25vw;
  }
}

@include media-breakpoint-between(lg, xl) {
  #notifications {
    max-width: 30vw;
  }
}

@include media-breakpoint-between(md, lg) {
  #notifications {
    max-width: 40vw;
  }
}

@include media-breakpoint-between(sm, md) {
  #notifications {
    max-width: 50vw;
  }
}

@include media-breakpoint-between(xs, sm) {
  #notifications {
    max-width: 65vw;
  }
}

@include media-breakpoint-down(xs) {
  #notifications {
    max-width: 95vw;
  }
}

.notification-body {
  min-width: 15vw;
}

.circle {
  background: $success;
  min-width: 12px;
  min-height: 12px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.no-circle {
  min-width: 10px;
  width: 10px;
}