/* Marquee used on landing page */
.people_marquee {
  height: 600px;
  background-image: url("images/people2.jpg");
  background-size: cover;
  background-repeat: repeat;
  animation: marquee 60s infinite linear;
}

@keyframes marquee {
  0% {
    background-position: 0;
  }
  100% {
    background-position: -4000px;
  }
}

/* Custom link color in footer */
.footer-dark,
.footer-dark a {
  color: #999;
}

/* scroll snap on landing page */

/* ### ISSUES
sometimes, content is not vertically centered
if you scroll to the last step and you continue scrolling, the page is still fixed and nothing happens while scrolling
Currently, you have to get over a certain distance until the content starts to finish scrolling. The content should start scrolling even after a minimal scroll input by the user */
.scrollsnap-container {
  scroll-snap-type: y mandatory;
  overscroll-behavior-x: contain;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 600px;
}

.scrollsnap-container::-webkit-scrollbar {
  display: none;
}
.scrollsnap-container div {
  scroll-snap-align: start;
}

.h-hide:hover {
  opacity: 0 !important;
}

.interactive-banners-style-11 .interactive-banners-box .interactive-banners-text-content .detail {
  opacity: 0;
  transform: translateY(15px);
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
}
.interactive-banners-style-11 .interactive-banners-box:hover .interactive-banners-text-content .detail {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
}

.interactive-banners-style-11 .interactive-banners-box .active .interactive-banners-text-overlay {
  opacity: 0.77;
}
.interactive-banners-style-11 .interactive-banners-box .active .interactive-banners-box-image img {
  -webkit-filter: blur(3px);
  filter: blur(3px);
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
}
.interactive-banners-style-11 .interactive-banners-box .active .interactive-banners-text-content {
  transform: translateY(-65px);
  -webkit-transform: translateY(-65px);
  -moz-transform: translateY(-65px);
  -ms-transform: translateY(-65px);
}
.interactive-banners-style-11 .interactive-banners-box .active .interactive-banners-text-content .btn {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
}

/* // #################### Responsive */

@media (min-width: 1901px) {
}
@media (max-width: 1600px) {
}

@media (max-width: 1500px) {
}
@media (max-width: 1300px) {
  .dnb-1331 {
    display: none !important;
  }
}
@media (min-width: 1199px) {
}
@media (max-width: 1199px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 991px) {
  .dnb-991 {
    display: none !important;
  }
  .herotitle {
    font-size: 4rem;
    line-height: 4rem;
  }
  .herosubtitle {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .rmp-400 {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
} /* // navigation breakpoint */
@media (max-width: 778px) {
  .herotitle {
    font-size: 3rem;
    line-height: 3rem;
  }
  .herosubtitle {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .rmpb-767 {
    margin: 0 !important;
    padding: 0 !important;
  }
  .rmb-767 {
    padding: 0 !important;
  }

  .am5b-767 {
    margin-top: 5rem;
  }

}
@media (max-width: 767px) {
  .panel-time,
  .panel-speaker {
    display: none !important;
  }
  .accordion-style-04 .panel .accordion-toggle {
    width: auto !important;
  }
  .accordion-style-04 .panel .panel-body {
    margin-left: 0 !important;
    width: auto;
  }

  .tickerimg {

    width: 200px!important;
    height: 200px!important;

  }

 .alt-font-2 {
    font-size: 1.8rem!important;
    line-height: 2.2rem;

  }
  .alt-font-3 {
    font-size: 2.2rem!important;
    line-height: 2.5rem;

  }
}
@media (max-width: 575px) {
  .herotitle {
    font-size: 2.5rem;
    line-height: 3.3rem;
    font-weight: 700;
  }
  .herosubtitle {
    font-size: 1.8rem!important;
    line-height: 2.5rem!important;
    font-weight: 300 !important;
  }
  .people_marquee {
    height: 400px !important;
  }
  .text-extra-large-2{
    font-size: 16px;
    line-height: 28px;
  }
  .tab-style-06 .nav-tabs > li.nav-item {
    width: 100%;
    border-bottom: none
  ;
  }
  .tab-style-06 .nav-tabs > li.nav-item > .active{
   color: #ff7a56!important;
  border-bottom: none!important;
  }

}
@media (max-width: 480px) {
}
@media (max-width: 575px) {
  h2 {font-size: 3rem; line-height: 3.5rem!important;}
  .w-75{width: 100%!important}
 .featurelist {
    font-size: 16px;
   line-height: 8px;

  }
 .tech{
   font-size: 4rem;
 }
}

@media (max-width: 400px) {
  .herosubtitle {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 700;
  }
  .dnb-400 {
    display: none !important;
  }.herotitle2{
     font-size:2rem!important;
     line-height: 3rem!important;
   }
}

.herovideo {
}

.standortvideo {
  min-height: 100% !important;
  min-width: 100% !important;
}




/* marquee */
.marquee {
  max-width: 100vw; /* iOS braucht das */
  white-space: nowrap;
  overflow: hidden;
  /* hier evtl. noch font-size, color usw. */
}

.marquee .header-animation-10 {
  display: inline-block;
  animation: marquee 10s linear infinite;
}

.marquee .header-animation-13 {
  display: inline-block;
  animation: marquee 13s linear infinite;
}

.marquee .header-animation-16 {
  display: inline-block;
  animation: marquee 16s linear infinite;
}


.marquee .header-animation-60 {
  display: inline-flex;
  list-style: none;
  animation: marquee 60s linear infinite;
}


/* Make it move */
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.tickerprice {

  margin: 10px;
  padding: 15px;
  border-radius: 20px;
}

.cards {
  background-color: #ffffff;
  margin: 20px;
  padding: 50px;
  border-radius: 20px;

}

.cards2 {
  background-color: #ffffff;

  padding: 20px;
  border-radius: 20px;

}


.cardcontent {
  padding-left: 50px;
  padding-right: 50px;
}

.cardcontentborder {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.cardvideo {
  align-items: flex-end;
}

.row.equal-cols {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.row.equal-cols:before,
.row.equal-cols:after {
  display: block;
}

.row.equal-cols > [class*='col-'] {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.row.equal-cols > [class*='col-'] > * {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.text-gray2 {
  color: #000000
}

.cards li {
  list-style: none;
}

.hand {
  cursor: pointer;
}

.borderyellow {
  border: 10px solid #ff967b;
}

.bg-orange-yellow-custom {
  background: rgb(35, 35, 35);
  background: linear-gradient(
          45deg,
          rgb(255, 134, 38),
          rgb(255, 197, 49)
  );
}

.invertImage {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.mirrorImage {
  -moz-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.tickerimg {
  background-color: #1e1e26;
  width: 350px;
  height: 350px;
  overflow: hidden;
  margin: 15px;
  border-radius: 20px;
  max-width: 100vw;
}

.cont {
  padding-right: 50px;
  vertical-align: auto;
  padding-left: 0;
  margin-top: 15px;
  display: table-cell;
  vertical-align: middle;
  padding-left: 15px;


}

.cont2 li {
  list-style: none
}

.pt-10{
  padding-top: 10rem!important;
}

.bgimg {
  background-image: url("https://www.wartung-serverraum.de/images/wartung-serverraum-einsatz-rcm-rechenzentrum.jpg");
}

.accordion-style-05 .panel .panel-heading.active-accordion{
  background-color: #131313;
}
.accordion-style-05 .panel .collapse.show, .accordion-style-05 .panel .collapsing {
  background-color: #131313;
}

.accordion-style-light .panel .panel-heading.active-accordion {
  background-color: red;
}

@media (max-width: 991px){

  header.sticky .navbar .navbar-brand .default-logo, header .navbar-brand .alt-logo {
    visibility: hidden!important;
    opacity:0;
    width: 0
  }
  header .navbar .navbar-brand .mobile-logo, header .sidebar-nav-menu .navbar-brand .mobile-logo {
    visibility: visible;
    opacity:1;
    width: auto
  }

}




.cursorhand {
  cursor: pointer!important;
}

input::placeholder {
  color: #bdbdbd !important;
}



/* Anpassung für Tablets und kleinere Bildschirme */
@media (max-width: 768px) {
h3 {
    font-size: 3rem; /* Kleinere Größe für Tablets */
  }
}

/* Anpassung für Smartphones */
@media (max-width: 480px) {
  h3 {
    font-size: 2.5rem!important; /* Noch kleinere Größe für Smartphones */
    line-height: 2.5rem!important;
  }
h2 {
    font-size: 2rem!important; /* Noch kleinere Größe für Smartphones */
  line-height: 2rem!important;
  }

.featurelist {font-size: 1rem!important;}

}
.rbt-input-main {
  border: none!important;
}
.rbt-menu {
  border: none!important;
}

.dropdown-menu {max-height: 220px!important;}