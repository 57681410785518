.btn.btn-icon.btn-icon-custom.btn-disabled {
  opacity: 0.6;
  box-shadow: none;
  cursor: not-allowed;
}

.btn.btn-custom-disabled {
  opacity: 0.6;
  box-shadow: none;
  cursor: not-allowed;
}

.icon-spinner {
  color: $dark;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.button-splash-spinner {
  animation: rotate 2s linear infinite;
  width: 1.25rem;
  height: 1.25rem;
}

.button-splash-spinner .path {
  stroke: white;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@include media-breakpoint-up(md) {
  .font-size-custom {
    font-size: 15px !important;
  }
}
