//
// Override global variables
//

// Theme colors
// Changed primary here to fit PLF theme
$primary:       									#1eabe3;// 0470b8 for darker blue
$primary-hover:    									#0470b8;
$primary-light:    									#C9F7F5;
$primary-inverse:  									#FFFFFF;

$info:       									    #6993FF;
$info-light:    									#E1E9FF;
$info-hover:    									#4A7DFF;
$info-inverse:  									#FFFFFF;

$success:       									#3699FF;
$success-hover:    									#187DE4;
$success-light:    									#E1F0FF;
$success-inverse:  									#FFFFFF;
