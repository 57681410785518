
.pdf-preview, .pdf-preview * {
  max-height: 90px !important;
  max-width: 160px !important;
  width: auto !important;
  height: auto !important;
}

.pdf-zoom {
  transition: transform .2s;
  position: relative;
}

.pdf-zoom-top {
  transform-origin: top;
}

.pdf-zoom-bottom {
  transform-origin: bottom;
}

.pdf-zoom:hover {
  z-index: 100000000;
}

@include media-breakpoint-up(lg) {
  .pdf-zoom:hover {
    transform: scale(5);
  }
}

@include media-breakpoint-up(xl) {
  .pdf-zoom:hover {
    transform: scale(6);
  }
}

@include media-breakpoint-down(md){
  .pdf-zoom:hover {
    transform: scale(4);
  }
}

.zoom-backdrop{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}

.zoom-backdrop.fade {
  opacity: 0;
  visibility: hidden;
}

.zoom-backdrop.show {
  opacity: 0.2 !important;
  visibility: visible !important;
}

.link-disabled {
  a {
    cursor: not-allowed;
  }
  img {
    opacity: 0.5;
  }
}